<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong {{ errorMessage}}
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <h4 class="text-center"> Single Report</h4>
          <b-alert show>
            Laporan penjualan berdasarkan Single Offers. ( Exclude Payment Free, Wallet, Gramedia.com )
          </b-alert>

          <div class="form-group row">
            <label for="" class="col-sm-2 form-control-label">
              Vendor <br />
            </label>
            <div class="col-sm-6">
              <multiselect
                v-model="filter"
                label="name"
                track-by="name"
                placeholder="Type to search"
                open-direction="bottom"
                :options="items_search"
                :searchable="true"
                :loading="isLoadingSearch"
                :close-on-select="true"
                :options-limit="10"
                :limit="10"
                :custom-label="customLabel"
                @search-change="asyncFind"
              >
              </multiselect>
            </div>
          </div>
          <div class="form-group row">
            <span class="col-sm-2">Select Range</span>
            <div class="col-md-8">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
                :max-date="new Date()"
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      :max-date="new Date()"
                    />
                    <span class="p-2">to</span>
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-2">
              <button class="btn btn-block btn-primary" @click="actionSearch">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-2 mb-1">
              <input
                type="text"
                v-model="curs"
                class="form-control"
                placeholder="14000"
              />
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-danger"
                @click="actionCreateTablePDF"
              >
                <i class="fa fa-file-pdf-o"></i>
              </button>
            </div>
            <div class="col-md-2">
              <label for="" class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">{{ formatPriceBasic(totalRows) }}</strong>
            </div>
          </div>
          <table
            class="table table-striped table-bordered table-sm table-responsive-sm mt-4"
          >
            <thead>
              <tr>
                <th>No</th>
                <th>item Name</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Final Price</th>
                <th>Free</th>
                <th>Purchase Date</th>
                <th>Payment</th>
                <th>Gateway Fee</th>
                <th>Gateway Cost</th> 
                <th>Balance</th>
              </tr>
            </thead>
            <tbody v-if="!isLoading">
              <template v-for="(item, index) in single">
                <tr :key="item.id">
                  <td>{{ ++index }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-right">
                    {{ formatPrice(item.price, item.currency_code) }}
                  </td>
                  <td>
                    <span :class="[item.is_discount ? activeClass : errorClass]">
                      {{ item.is_discount ? "Ya" : "No"}}
                    </span>
                  </td>
                  <td class="text-right">
                    {{ formatPrice(item.final_price, item.currency_code) }}
                  </td>
                  <td>
                    <span :class="[item.is_free ? activeClass : errorClass]">
                      {{ item.is_free ? "Ya" : "No"}}
                    </span>
                  </td>

                  <td style="width:10%">{{ formatDate(item.created) }}</td>
                  <td>
                    <PaymentGateway :gateway="item.paymentgateway_id" />
                  </td>
                  <td class="text-right"> 
                    {{ FormatFormula(PaymentCost(item.paymentgateway_id))}}
                  </td>
                  <td class="text-right">
                    {{ formatPrice(CountAmountCost(item.final_price,1,item.paymentgateway_id),item.currency_code)}}
                  </td>
                 <td class="text-right" v-if="item.currency_code =='USD'">
                    {{ formatPrice(reversIDR(finalAmount(item.final_price,CountAmountCost(item.final_price,1,item.paymentgateway_id))),"IDR")}}
                  </td>
                  <td v-else class="text-right">
                    {{ formatPrice(finalAmount(item.final_price,CountAmountCost(item.final_price,1,item.paymentgateway_id)),item.currency_code)}}
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <template>
                <tr>
                  <td colspan="7">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong> Loading...</strong>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" class="text-center">Total</td>
                <td class="text-right" colspan="1">
                  {{ formatPrice(SumPrice((totalAmountD * curs),totalAmount),"IDR")}}
                </td>
                <td colspan="5">
                </td>
                <td>
                  {{ formatPrice(SumPrice(balanceIDR,balanceUSD * curs),"IDR")}}
                </td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">Total Revenue (Balance * {{ revenue && revenue.length > 0 ? revenue[0].default_percentage_amount: ''}} )</td>
                <td class="text-right">
                  <strong>
                   {{ formatPrice(SumRevenue(SumPrice((balanceUSD * curs),balanceIDR),revenue && revenue.length > 0 ? revenue[0].default_percentage_amount:''),"IDR") }}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PDFReports from "../../plugins/PDFReports"
import { mapState, mapActions, mapMutations } from "vuex";
import PaymentGateway from "../../components/payments/gateway.vue"
export default {
  name: "singleReport",
  components: {
    PaymentGateway
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      Dates: {
        from: moment().format("YYYY,MM,DD"),
        to: moment().format("YYYY,MM,DD"),
      },
      range: {
        start: moment().format("YYYY,MM,DD"),
        end: moment().format("YYYY,MM,DD"),
      },
      masks: {
        input: "YYYY-MM-DD",
      },
      activeClass: 'badge badge-primary',
      errorClass: 'badge badge-danger',
      curs:14200,
      SumAmount:0,
      balanceIDR:0,
      balanceUSD:0,
      inbalance:0
    };
  },
  watch: {
    currentPage: function() {
      this.actionSearch();
    },
    single:  function(){
      this.balanceIDR =0
      this.balanceUSD =0
      this.single.forEach(item => {
        if (item.currency_code =='IDR') {
          let idr = this.finalAmount(item.final_price,this.CountAmountCost(item.final_price,1,item.paymentgateway_id))
          this.balanceIDR += idr
        } else if(item.currency_code =='USD'){
          let usd = this.finalAmount(item.final_price,this.CountAmountCost(item.final_price,1,item.paymentgateway_id))
          this.balanceUSD += usd
        }
      });
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.single.isLoading,
      isError: (state) => state.single.isError,
      single: (state) => state.single.items,
      totalRows: (state) => state.single.totalRows,
      items_search:(state) => state.vendors.items,
      totalAmount: (state) => state.single.totalAmount,
      totalAmountD: (state) => state.single.totalAmountD,
      payment:(state) => state.payments.items,
      revenue:(state)=> state.vendors.revenue,
      errorMessage: (state)=> state.single.errorMessage
    }),
  },
  mounted() {
    let payload = {
      limit:100,
      page:1
    }
    this.fetchPayment(payload)
    this.setItems([])
  },
  methods: {
    ...mapActions("single", ["fetchReportByVendor"]),
    ...mapActions("vendors",["searchVendors","RevenueByVendor"]),
    ...mapActions("payments",["fetchPayment"]),
    ...mapMutations('single',['setItems']),
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }
        return formatter.format(value);
      }

      return "0";
    },
    asyncFind(query) {
      if (!query) {
        this.isLoadingSearch = false;
        clearTimeout(this.debounce);
        return;
      }

      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.actionGetVendors(query);
      }, 600);
    },
    actionGetVendors(query) {
      console.log('call search' ,query);
      this.searchVendors({name : query}).then(() => {
        this.isLoadingSearch = false;
      })
    },
    formatDate(tgl) {
      return moment(tgl).format("DD MMM YY hh:mm");
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    actionSearch() {
      if (this.filter && this.filter.id) {
         let payload = {
          id: this.filter.id,
          from: moment(this.range.start).format("YYYY-MM-DD"),
          to: moment(this.range.end).format("YYYY-MM-DD"),
          page: this.currentPage,
          limit: this.perPage,
        };
        this.RevenueByVendor(payload)
        try {
          this.fetchReportByVendor(payload);
          
        } catch (error) {
          console.log(error);
        }
      } else {
         this.$swal({
          toast: "true",
          position: "top-end",
          icon: "error",
          title: "Please Choose Vendor",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
     
    },
    PaymentCost(id){
      let p = this.payment.find((e) => e.id == id);
      if (p) {
        let formula = p.formula
        let fixedFormula = parseInt(formula.fixed_amount) > 0 ?  parseInt(formula.fixed_amount) :0;
        let value = parseFloat(formula.percentage_amount) > 0 ? parseFloat(formula.percentage_amount): 0
        return {
          fixed_amount: fixedFormula,
          value: value ,
        };
      } else {
        return id;
      }
    },
    FormatFormula (data){
      if (data) {
        if(data.fixed_amount > 0 && data.value > 0){
          return `Rp ${data.value} + ${data.fixed_amount}`;
        } else if(data.fixed_amount > 0 && data.value < 1) {
          return `Rp ${data.fixed_amount}`
        } else {
          return `${data.value}`;
        }
      }
    },
     CountAmountCost(amount,count,gatewayid){
      let val = this.PaymentCost(gatewayid)
      if (parseFloat(val.value) > 0 && parseInt(val.fixed_amount) > 0) {
        return (amount * val.value) + val.fixed_amount
      } else if (val.value < 1 && val.fixed_amount > 0){
        return count * val.fixed_amount;
      } else if(val.value > 0 && val.fixed_amount < 1){
        return amount * val.value;
      }
    },
    finalAmount(amount, cost) {
      return parseFloat(amount) - parseFloat(cost);
    },
    SumPrice(data,data1){
      return parseFloat(data) + parseFloat(data1)
    },
    SumRevenue(amount,percetage){
      return parseFloat(amount)  * percetage
    },
    reversIDR(amount){
      return amount * this.curs
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    Payment(id) {
      let p = this.payment.find((e) => e.id == id);
      if (p) {
        return `${p.name} ( ${p.payment_group} )`;
      } else {
        return id;
      }
    },
    setTitleHead(){
      let head = [
        "No","Item Name","Price","Discount",
        "Final Price","Free",
        "Purchase Date","Payment","Gateway Fee",
        "Gateway Cost","Balance"
      ]
      return head
    },
    setBodyData() {
      let data = []
      let n =0;
      this.single.forEach(e => {
        let gateway = this.Payment(e.paymentgateway_id);
        let datain = [
          { content : ++n},
          {content: e.name},
          {content:this.formatPrice(e.price, e.currency_code)},
          {content: e.is_discount ? 'Y':'N'},
          {content:this.formatPrice(e.final_price, e.currency_code)},
          {content: e.is_free ? 'Y':'N'},
          {content: this.formatDate(e.created)},
          {content: gateway},
          {content:this.FormatFormula(this.PaymentCost(e.paymentgateway_id)),styles: { halign: 'right' }},
          {content:this.formatPrice(this.CountAmountCost(e.final_price,1,e.paymentgateway_id),e.currency_code),styles: { halign: 'right' }},
          {content:this.formatPrice(this.reversIDR(this.finalAmount(e.final_price,this.CountAmountCost(e.final_price,1,e.paymentgateway_id))),"IDR"),styles: { halign: 'right' }},
          {content:this.formatPrice(this.finalAmount(e.final_price,this.CountAmountCost(e.final_price,1,e.paymentgateway_id)),e.currency_code),styles: { halign: 'right' }}
        ]
        data.push(datain)
      })
      return data
    },
    setFooter(){
      let data = []
      let span = [{
        content: 'Total',
        colSpan: 4,
        styles: { halign: 'center',fontStyle:'bold' }
      },
      
      { content: this.formatPrice(this.SumPrice((this.totalAmountD * this.curs),this.totalAmount),"IDR"),styles: { halign: 'right' }},
      {content:'',colSpan:5},
      {content: this.formatPrice(this.SumPrice((this.balanceUSD * this.curs),this.balanceIDR),"IDR")}
      ]
      data.push(span)
      let revenues = this.revenue && this.revenue.length > 0 ? this.revenue[0].default_percentage_amount: ''
      let span2 = [{
        content: `Total Revenue (Balance * ${revenues} )`,
        colSpan: 10,
        styles: { halign: 'center',fontStyle:'bold' }
      },
      { content: this.formatPrice(this.SumRevenue(this.SumPrice((this.balanceUSD * this.curs),this.balanceIDR),revenues), "IDR"),styles: { halign: 'right',fontStyle:'bold' }}
      ]
      data.push(span2)
      return data
    },
    actionCreateTablePDF() {
       let payload = {
        from: this.range.start,
        to: this.range.end
      };
      let data = this.setBodyData()
      let footer = this.setFooter()
      let datatable = data.concat(footer)
      let report = new PDFReports(`Single`, payload,this.curs,`${this.filter.name}_${moment(payload.from).unix()}`,`${this.filter.name}`)
      
      report.SetHead(this.setTitleHead())
      report.SetData(datatable)
      report.BuildData()
      window.location.reload()
    },
  },
};
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
